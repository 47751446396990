<template>
  <Menubar :model="items"> </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  name: "app-header",
  data() {
    return {
      items: [
        {
          label: "Home",
          icon: "pi pi-fw pi-file",
          to: "/",
        },
        {
          label: "Data",
          icon: "pi pi-fw pi-file",
          to: "/data",
          // items: [
          //   { label: "search", icon: "pi pi-fw pi-file", to: "/data" },
          //   { label: "summary", icon: "pi pi-fw pi-file", to: "/data" },
          // ],
        },
        {
          label: "Map",
          icon: "pi pi-fw pi-file",
          to: "/map",
          click: function(){ console.log("Map clicked ...") },
        },
        {
          label: "Toolbox",
          icon: "pi pi-fw pi-file",
          to: "/toolbox",
        },
        {
          label: "Living Atlas",
          icon: "pi pi-fw pi-file",
          to: "/livingatlas",
        },
        {
          label: "Archive",
          icon: "pi pi-fw pi-file",
          to: "/archive",
        },
        {
          label: "Collaborators",
          icon: "pi pi-fw pi-file",
          to: "collaborators",
        },
        {
          label: "Imprint",
          icon: "pi pi-fw pi-file",
          to: "imprint",
        },
//        {
//          label: "Js4Test",
//          icon: "pi pi-fw pi-file",
//          to: "js4test",
//        },
      ],
    };
  },
  components: { Menubar },
};
</script>

<style></style>
