import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/Home";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Imprint"),
    },
    {
      path: "/collaborators",
      name: "collaborators",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Collaborators"),
    },
    {
      path: "/archive",
      name: "archive",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Archive"),
    },
    {
      path: "/livingatlas",
      name: "livingatlas",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/LivingAtlas"),
    },
    {
      path: "/map",
      name: "map",
      component: () => import(/* webpackChunkName: "about" */ "./views/Map"),
      meta: {
        reload: true,
      },
    },
    {
      path: "/toolbox",
      name: "toolbox",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Toolbox"),
    },
    {
      path: "/data",
      name: "data",
      component: () => import(/* webpackChunkName: "about" */ "./views/Data"),
    },

//    {
//      path: "/js4test",
//      name: "js4test",
//      component: () =>
//        import(/* webpackChunkName: "about" */ "./views/Js4Test"),
//    },
  ],
});
