<template>
  <div class="text-medium font-medium text-left content-text">
    <p class="text-xl font-bold">
      Socotra Conservation and Sustainable Development Portal
    </p>
    <p>
      <img
        src="./../assets/content/home/1.png"
        alt=""
        align="right"
        class="decoIMG"
      />
      The UNEP/GEF funded <router-link to="/">Conservation and Sustainable Development
      Portal (SCSDP)</router-link> compiles information on the Socotra Archipelago UNESCO
      Natural World Heritage Site (Yemen) and displays the contents as an
      interactive <router-link to="/map">map</router-link>, thereby providing a dynamic tool and a searchable
      <router-link to="/data">database</router-link>. Basic design of the portal in <a href="https://knb.ecoinformatics.org/knb/docs/" target="_blank">Metacat</a> started in August 2016 and
      continues to grow as information is generated and collated during the
      lifetime of the project. The portal is intimately intertwined with the
      project components and is the main publicly available information hub for
      Socotra conservation, sustainable development and scientific research
      through its dynamic interface representing the most reliable georeferenced
      data available. In addition, the SCSDP provides a searchable online
      archive of useful references on the Socotra Archipelago, and existing as
      well as custom-made analysis tools. All data is compiled through a
      multidisciplinary collaboration of national and international experts and
      the project originally implemented by <a href="http://www.senckenberg.de/root/index.php?page_id=71" target="_blank">Senckenberg Society for Nature
      Research</a>, the Ministry of Water and Environment (Yemen) and Environment
      Protection Authority (EPA Yemen). <a href="https://www.rscn.org.jo/" target="_blank">The Royal Society for the Conservation
      of Nature</a> (RSCN) assumed project lead and responsibility for the transfer
      of project contents to Yemeni stakeholders. Entries undergo a strict
      quality control. Admission of new metadata, corrections or contacting
      individual data users, is possible at <a href="mailto:socotra@senckenberg.de">request</a>.
    </p>
    <p>
      Contact info for questions/updates: general info on the portal, contact
      <a href="mailto:socotra@rscn.org.jo" class="font-bold"
        >socotra@rscn.org.jo</a
      >. For data, please contact the respective collaborators (see info-tab
      under Map for each layer, and see Collaborators). Copyright of all
      original data remains with the data holders, funded by diverse projects.
    </p>
    <p>
      <a
        href="https://orcid.org/0000-0002-8215-3398"
        target="_blank"
      >
        <span class="icon-orcidid"> </span>
        Van Damme K.</a
      >
      ,

      <a
        href="https://orcid.org/0000-0001-6106-1411"
        target="_blank"
      >
        <span class="icon-orcidid" />
        Zajonz U.</a
      >
      ,

      <a
        href="https://orcid.org/0000-0001-9920-4238"
        target="_blank"
      >
        <span class="icon-orcidid" />
        Gerstner E</a
      >
      ,

      <a
        href="https://orcid.org/0000-0003-0351-6523"
        target="_blank"
      >
        <span class="icon-orcidid" />
        Weiland C.</a
      >
      ,

      <a
        href="https://orcid.org/0000-0003-4511-3407"
        target="_blank"
      >
        <span class="icon-orcidid" />
        Niamir A.</a
      >
      ,

      <a
        href="https://orcid.org/0000-0002-4963-2725"
        target="_blank"
      >
        <span class="icon-orcidid" />
        Winter T.</a
      >
      &amp; Reitz J.& Hickler T. , 2017.<br />
      Socotra Conservation and Development Portal. URL:
      <a
        href="http://socotra.senckenberg.de/metacatui"
        target="_blank"
        style="outline-width: 0px !important; user-select: auto !important"
        >http://socotra.senckenberg.de/metacatui</a
      >
      [Date Accessed]. (note: doi to be determined)
    </p>

    <p>
      Major data contributors through the UNEP-GEF project and beyond include P.
      Madĕra, E. Neubert, F. Attorre, F. Anderson, A. Forrest, D. Van Dorpe, P.
      De Geest, K. Van Damme, J. Jansen van Rensburg, K. Král, R. Porter, P.
      Vahalík, P. Benda, U.Zajonz, representatives of EPA Socotra, and others.
      Photographs added by several authors (R. Porter, D. Cesarini, K. Van
      Damme, S. Bogorodsky, U. Zajonz). Contacts of contributors and references
      are found under the info page in the maps. We greatly appreciate their
      support!
    </p>
    <p>
      تقوم بوابة سينكينبيرج – سقطرى (SCSDP) الممولة من قبل برنامج الأمم المتحدة
      للبيئة (UNEP) ومرفق البيئة العالمي (GEF)، بجمع المعلومات عن أرخبيل سقطرى
      الذي يمثل أحد مواقع اليونيسكو للتراث العالمي في اليمن ويعرض محتوياته بهيئة
      خريطة تفاعلية، وبالتالي يوفر أداة ديناميكية وقاعدة بيانات قابلة للبحث. بدأ
      التصميم الأساسي للبوابة في الميتاكات Metacat في شهر أغسطس 2016 وسيستمر في
      النمو عن طريق تحديث المعلومات وترتيبها خلال فترة حياة المشروع. ترتبط
      البوابة ارتباطاً وثيقاً مع مكونات المشروع، وهو المحور الرئيسي الوحيد
      المتاح للجمهور لتحقيق الصون والتنمية المستدامة لسقطرى والبحث العلمي فيها
      من خلال واجهة ديناميكية تمثل معلومات موثقة يمكن الاعتماد عليها. إضافة
      لذلك، توفر البوابة أرشيف للبحث العلمي على الإنترنت يضم مراجع مفيدة عن
      أرخبيل سقطرى، كما يمثل أيضا أدوات تحليل بحسب الطلب. تجمع كافة المعلومات
      وتصنف عن طريق التعاون بين خبراء من مؤسسات علمية دولية ومن المشروع، وينفذه
      كل من معهد سينكينبيرج Senckenberg Institute ووزارة المياه والبيئة اليمنية
      والهيئة العامة لحماية البيئة في اليمن. تخضع المدخلات الى البوابة لرقابة
      صارمة من حيث الجودة. يمكن قبول المعلومات الجديدة والتصحيحات والإتصال
      بمستخدمي البيانات بناءً على الطلب.
    </p>

    <p>
      <table>
        <tr>
          <template v-for="td in homeExternal" :key="td"><td><a
              :href="td.href"
              target="_blank"
              class="home-external-pic-link"
            >
              <img
                class="home-external-pic-link"
                :src="td.src"
                alt="td.href"
            /></a></td></template>
        </tr>
      </table>
      <!-- eslint-disable-next-line -->
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      homeExternal: [
        {
          href: "https://www.rscn.org.jo/",
          src: require("./../assets/content/home/2_RSCN.png"),
          alt: "The Royal Society for the Conservation of Nature (RSCN)",
        },
        {
          href: "https://www.unep.org/",
          src: require("./../assets/content/home/3_un.png"),
          alt: "The United Nations Environment Programme (UNEP)",
        },
        {
          href: "https://www.thegef.org/",
          src: require("./../assets/content/home/4_gef.png"),
          alt: "The Global Environment Facility (GEF)",
        },
        {
          href: "https://epa-yemen.com/",
          src: require("./../assets/content/home/5_epa.png"),
          alt: "EPA Yemen",
        },
        {
          href: "https://senckenberg.de/",
          src: require("./../assets/content/home/6_sgn.png"),
          alt: "Senckenberg Gesellschaft für Naturforschung",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home-external-pic-link {
  width: inherit;
  min-width: 100px;
  height: auto;
}
// Table
tr {
  width: 100%;
  height: inherit;
}
td {
  align-content: center;
  width: 20%;
  height: inherit;
}
table {
  width: 100%;
}

.decoIMG {
  max-width: 30%;
}
</style>
