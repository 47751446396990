import Keycloak from "keycloak-js";
// import { ref } from "vue";

let initOptions = {
  url: "https://scsdp.senckenberg.de/keycloak/auth/",
  //url: "http://localhost:8090/auth",
  realm: "cordra-realm",
  clientId: "cordra-client",
  // onload: "login-required",
};

let keycloakGlobalstate = {
  ready: false,
  authenticated: false,
};

let keycloak = Keycloak(initOptions);

// Init part, like in the documentation
keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      console.log("NOT Authenticated");
      // window.location.reload();

      keycloakGlobalstate["ready"] = true;
      keycloakGlobalstate["authenticated"] = false;
    } else {
      console.log("Authenticated");

      keycloakGlobalstate["ready"] = true;
      keycloakGlobalstate["authenticated"] = true;
    }
    // console.log("keycloak object", keycloak);

    // Maybe we can do that action based.
    setInterval(() => {
      // refresh token, if you've only 70 sec or less
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            // console.log("Token refreshed" + refreshed);
          } else {
            // console.log(
            //   "Token not refreshed, valid for " +
            //   Math.round(
            //     keycloak.tokenParsed.exp +
            //     keycloak.timeSkew -
            //     new Date().getTime() / 1000
            //   ) +
            //   " seconds"
            // );
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    keycloakGlobalstate["ready"] = true;
    keycloakGlobalstate["authenticated"] = false;
    console.log("Authenticated Failed");
  });
// console.log("keycloak init", keycloak);

export default {
  install(app) {
    app.config.globalProperties.$keycloakGlobalstate = keycloakGlobalstate;

    app.config.globalProperties.$keycloakLoginLink = () => {
      // console.log("Plugin keycloakLoginLink", keycloak.createLoginUrl());
      return keycloak.createLoginUrl();
    };

    app.config.globalProperties.$keycloakLogoutLink = () => {
      // console.log("Plugin keycloakLogoutLink", keycloak.createLogoutUrl());
      return keycloak.createLogoutUrl();
    };

    app.config.globalProperties.$keycloak_token = () => {
      // console.log("Plugin keycloak_token", keycloak.token);
      return keycloak.token;
    };

    app.config.globalProperties.$keycloak_authorID = () => {
      // console.log("Plugin keycloak_authorID", keycloak.tokenParsed.sub);
      return keycloak.subject;
    };
  },
};
