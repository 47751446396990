
import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router.js"; // sudo npm install -g vue-router@400

// External
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./assets/css/map/bootstrap.min.css";

import "primeflex/primeflex.css"; // PrimeFlex (npm install primeflex)

// Own Plugins
import Keycloak from "./plugins/keycloak.js";

// CSS Maps
//import "./assets/css/mystyle.css";
//import Test from "./assets/js/myjstest.js";
//import "./assets/js/myjstest.js"

createApp(App).use(PrimeVue).use(Router).use(Keycloak).mount("#app");