<template>
  <br />
  <Fieldset legend="Help and About" :toggleable="true" :collapsed="true">
    <table>
      <tr>
        <td style="width: 25%">
          Help:<br />
          <a
            href="https://socotra.senckenberg.de/metacatui/#help"
            target="_blank"
            >Search Tips</a
          >
          <br />
          <a
            href="https://socotra.senckenberg.de/metacatui/#about/sharing-your-data"
            target="_blank"
            >Share your Data</a
          >
        </td>
        <td>
          Executing Organisation Ministry of Water and Environment (MWE) /
          Environment Protection Authority (EPA), Yemen.
          <br />
          Support by Senckenberg Society for Nature Research (SGN)
        </td>
      </tr>
    </table>
  </Fieldset>
  <br />
</template>

<script>
import Fieldset from "primevue/fieldset";

export default {
  name: "app-footer",
  components: { Fieldset },
};
</script>

<style lang="scss" scoped></style>
